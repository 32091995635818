<template>
    <div>
        <v-navigation-drawer color="#395B67" dark v-model="showMenu" fixed app temporary right>
            <v-img
                style="filter:invert(1) drop-shadow( 2px 2px 4px black) opacity(.6)"
                :src="require('@/assets/7.svg')"
                class="mb-n10"
                width="100%"
                contain
            />
            <v-img
                style="filter:invert(1) drop-shadow( 2px 2px 4px black) opacity(.6)"
                :src="require('@/assets/scale.svg')"
                class="mb-n10"
                width="100%"
                contain
            />
            <!--<v-img
                style="filter:invert(1) drop-shadow( 2px 2px 4px black) opacity(.6)"
                :src="require('@/assets/scale.svg')"
                class="mb-n10"
                width="100%"
                contain
            />
           
               <div class="text-no-wrap custom-font" style="width:100%;font-size:30pt;text-align:center"> 
                Rechtsanwalt Ring
            </div> -->

        
            <v-list class="mt-auto mb-auto" style="width:100%">
                <v-list-item v-for="route in routes" link :key="route.path" :to="route.path">
                    <v-menu
                        v-if="route.meta.dropdown"
                        transition="slide-y-transition"
                        origin="bottom-left" 
                        offset-y
                    >
                        <template v-slot:activator="{ on }">
                            <div class="d-flex align-center" v-on="on">
                                <v-icon v-text="route.meta.icon" dense class="mr-2"/>
                                <div>{{route.name}}</div>
                                <v-icon dense class="ml-2">mdi-dots-vertical</v-icon>
                            </div>
                        </template>
                        <v-list class="py-0">
                            <v-list-item
                                v-for="item in route.meta.dropdown"
                                :key="item.param"
                                :to="route.path + '?gebiet=' + item.param"
                            >
                                <v-list-item-title>{{item.title}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div v-else class="d-flex align-center">
                        <v-icon class="mr-2" v-text="route.meta.icon"/>
                        <div>{{route.name}}</div>
                    </div>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
            
        <v-app-bar ref="mainbar" style="transition: height .3s ease !important" color="#395B67" elevate-on-scroll min-height="124" height="270" prominent clipped-right fixed app shrink-on-scroll>
            <template v-slot:img>
                <div class="d-flex align-end" style="height:100%;width:300px">
                    <v-img
                        :src="require('@/assets/DanielZeichnung5.svg')" 
                        class="align-self-end" 
                        height="300" 
                        width="300"
                        contain
                    />
                </div>
            </template>
            <div class="d-flex flex-row" style="width:100%;height:100%">
                <div style="width:330px"/>
                <div class="d-flex flex-column" style="width:100%;height:100%">
                <div class="d-flex justify-center" style="width:100%;height:100%">
                    <!--<div style="width:100%;max-height:70px;" class="d-flex flex-row justify-center mt-auto mb-auto">
                    <div class="text-no-wrap custom-font" style="text-align:center">
                        Rechtsanwalt Ring
                    </div>
                    <img
                        style="filter:invert(1) drop-shadow( 2px 2px 4px black)"
                        :src="require('@/assets/scale.svg')"
                        width="65"
                    />
                    </div>
                    <div style="width:100%;max-height:70px;" class="d-flex flex-row justify-center mt-auto mb-auto">
                    <img class="ma-auto"
                         style="filter:invert(1) drop-shadow(2px 2px 4px black);max-height:100px"
                        :src="require('@/assets/Ring6.png')"
                    />
                    
                     </div> -->
                    <div :style="logoheight" class="d-flex flex-row align-self-center mb-n5">
                        <img style="height:100%;filter:invert(1) drop-shadow(2px 2px 4px black)"
                            :src="require('@/assets/7.svg')"
                        />
                    </div>
                    <v-app-bar-nav-icon class="d-lg-none ml-auto mt-auto mb-auto" color="white" @click="showMenu = !showMenu"/>
                </div>

                <v-tabs right fixed-tabs dark color="white" class="d-lg-block d-none">
                    <v-tab class="px-0" dark v-for="route in routes" :key="route.path" :to="route.meta.dropdown ? '' : route.path">

                        <v-menu tile
                            v-if="route.meta.dropdown"
                            transition="slide-y-transition"
                            origin="bottom-left" 
                            offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <div class="d-flex justify-center align-center" style="width:100%;height:100%" v-on="on" @click="$router.push(route.path)">
                                    <v-icon v-text="route.meta.icon" dense class="mr-2 ml-n1"/>
                                    <div>{{route.name}}</div>
                                    <v-icon dense class="mr-n1 ml-2">mdi-dots-vertical</v-icon>
                                </div>
                            </template>

                            <v-list class="py-0">
                                <v-list-item
                                    v-for="item in route.meta.dropdown"
                                    :key="item.param"
                                    :to="route.path + '?gebiet=' + item.param"
                                >
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>


                        <div v-else class="d-flex align-center">
                            <v-icon v-text="route.meta.icon" dense class="mr-2 ml-n1"/>
                            <div class="mr-n1">{{route.name}}</div>
                        </div>
                    </v-tab>
                </v-tabs>
                </div>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: "NavBar",
    computed:{
        routes: function(){
            return this.$router.options.routes.filter(r => r.meta.visible)
        },
        logoheight: function(){
            var corrected = this.mainheight - 140
            corrected = corrected < 0 ? 0 : corrected
            var toAdd = corrected
            var logoheight = 70 + toAdd
            return "width:100%;margin-left:12%;transition: height .3s ease;height:" + logoheight + "px"
        }
    },
    data: () => ({
        showMenu: false,
        mainbar: {},
        mainheight: 270
    }),
    mounted(){
        this.mainheight = this.$refs.mainbar.computedHeight
        this.$watch(() => this.$refs.mainbar.computedHeight, (value) => this.mainheight = value)
    },
}
</script>

<style>
    .v-toolbar__content{
        height: 100% !important;
        padding: 0 !important;
        padding-bottom: 0 !important;
    }
    .theme--dark.v-tabs > .v-tabs-bar {
        background-color: transparent !important;
    }
</style>

<style scoped>
    .custom-font{
        font-family: sloop-script-two,timberline,sans-serif;
        font-weight: 400;
        font-size: 55pt;
        font-style: normal;
        color: white;
        text-shadow: 2px 2px 4px black;
    }
</style>