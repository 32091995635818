<template>
  <v-app>
    <nav-bar/>
    
    <v-main>
        <transition :name="transitionName" mode="out-in">
          <router-view/>
        </transition>
    </v-main>

    
    <cookie-law theme="mytheme">
      <div slot-scope="props" class="d-flex justify-space-around align-center mx-0" style="width:100%">
        <div>
          Wir verwenden Cookies, um die Benutzerfreundlichkeit unserer Website zu verbessern. 
          Durch die weitere Nutzung stimmen Sie der Verwendung von Cookies zu.
          <button class="skew" @click="props.close">
            <a href="htps://rr-raring.de/datenschutz/" target="_blank">Weitere Informationen</a>
          </button>
        </div>
        <button class="skew btn py-3 px-6" @click="props.accept">
          Einverstanden
        </button>
      </div>
    </cookie-law>

    <r-footer/>
  </v-app>
</template>

<script>
import RFooter from "./components/Footer";
import NavBar from "@/components/NavBar"
import CookieLaw from 'vue-cookie-law'



export default {
  name: 'App',
  components: { RFooter, NavBar, CookieLaw },
  data: () => ({
    transitionName: 'slide-right'
  }),
  watch: {
    '$route' (to, from)  {
      var toIndex = this.$router.options.routes.findIndex(r => to.path == r.path)
      var fromIndex = this.$router.options.routes.findIndex(r => from.path == r.path)
      if(toIndex > fromIndex)
        this.transitionName = 'slide-left'
      else
        this.transitionName = 'slide-right'
    }
  }
}
</script>

<style scoped>
  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(15%, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-15%, 0);
  }

.Cookie--mytheme {
    background: #ebecee!important;
    bottom: 0;
    font-size: 15px;
    left: 0;
    padding: 12px 12px;
    position: fixed;
    right: 0;
    text-align: left;
    width: 100%;
}


.btn{
  background: #395B67;
  color: white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
}


.mt-md-0 {
    margin-top: 0!important;

}

</style>
