import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      visible: true,
      icon: 'mdi-home-city'
    }
  },
  {
    path: '/aktuelles',
    name: 'Aktuelles',
    component: () => import('../views/Aktuelles.vue'),
    meta: {
      visible: true,
      icon: 'mdi-newspaper'
    }
  },
  {
    path: '/diekanzlei',
    name: 'Die Kanzlei',
    component: () => import('../views/Diekanzlei.vue'),
    meta: {
      visible: true,
      icon: 'mdi-briefcase'
    }
  },
  {
    path: '/deranwalt',
    name: 'Der Anwalt',
    component: () => import('../views/Deranwalt.vue'),
    meta: {
      visible: true,
      icon: 'mdi-scale-balance'
    }
  },
  {
    path: '/rechtsgebiete',
    name: 'Rechtsgebiete',
    component: () => import('../views/Rechtsgebiete.vue'),
    meta: {
      visible: true,
      icon: 'mdi-book-open',
      dropdown:[
        {
          title: "Erstberatung",
          param: "steuerrecht",
          color: "#395B67"
        },
        {
          title: "Privates Baurecht",
          param: "baurecht",
          color: "#395B67"
        },
        {
          title: "Straßenverkehrszivilrecht",
          param: "wirtschaftsrecht",
          color: "#395B67"
        },
        {
          title: "Forderungsmanagement",
          param: "irgendeinrecht",
          color: "#395B67"
        }
      ]
    }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue'),
    meta: {
      visible: true,
      icon: 'mdi-account-box'
    }
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Impressum.vue'),
    meta: {
      visible: false,
      icon: 'mdi-home-city'
    }
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Datenschutz.vue'),
    meta: {
      visible: false,
      icon: 'mdi-home-city'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
