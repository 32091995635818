<template>
  <div>
    <v-img :src="require('@/assets/landkarte.png')" style="width:100%">
      <v-layout fill-height align-center>
          <v-container>
            <v-row align="center" justify="center" class="white--text">
              <v-col cols="12">
                <div
                  :class="{'display-2 font-weight-bold ': $vuetify.breakpoint.smAndUp, 'title font-weight-bold': $vuetify.breakpoint.smAndDown}"
                >Daniel Ring</div>
                <div
                  :class="{'headline': $vuetify.breakpoint.smAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown}"
                >Ihr Rechtsanwalt in Waidhaus</div>
              </v-col>
            </v-row>
          </v-container>
        </v-layout>
    </v-img>
    



    <v-container>
       <div class="body-1 mt-3">
         <h1 class="display-1 font-weight-bold mb-4">
          RR - Rechtsanwalt Ring. <br> Ihr erster Ansprechpartner bei rechtlichen Fragen.
        </h1>
        <hr class="my-2"/>
        <br>
       <h2 class="font-weight-bold mb-4">
          "Es kommt darauf an." <br>
        </h2>
        <p align="justify">
          Wer mit Juristen zu tun hat, der hat diesen Satz schon mindestens einmal gehört. Denn die Kunst der Rechtsberatung ist es nicht, pauschale Antworten auf allgemeine Fragen zu liefern. Die Kunst besteht vielmehr darin, individuell maßgeschneiderte Lösungen für konkrete Problemstellungen anzubieten. Und genau das ist mein Motto: Es kommt auf IHREN Fall an. Ihnen dafür Optionen und Alternativen aufzuzeigen ist mein Anspruch!
          <br><br>
          </p>
              </div>
        <v-layout wrap class="mt-5">
          <v-flex xs12>
            <div class="display-1 font-weight-bold" id="about">
              Ich biete Ihnen gerne bei den nachfolgenden Themenkomplexen juristische Hilfestellung an:
              <br><br>
            </div>
          </v-flex>
          <v-row>
            <template v-for="(gebiet, i) in rechtsgebiete">
              <v-col :key="i">
                <v-btn block dark large elevation="6" :color="gebiet.color" :to="'/rechtsgebiete?gebiet=' + gebiet.param">
                    {{gebiet.title}}
                </v-btn>
              </v-col>
              <v-responsive
                v-if="(i+1)%2 == 0"
                :key="`width-${i}`"
                width="100%"
              />
            </template>
        </v-row>
        </v-layout>
    </v-container>

   <br><br>
  <v-parallax
    dark
    :src="require('@/assets/bild3.jpeg')"  
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <h1 class="display-1 font-weight-bold mb-4">
          Telefonische Erstberatung   
        </h1>
        <br>
        <h4 class="subheading">
          Rufen Sie mich gerne unter der folgenden Nummer an, wenn Sie eine Ersteinschätzung benötigen: <br>
          (bitte beachten Sie, dass ich werktags im Regelfall erst ab 18:30 Uhr erreichbar bin)
          
        </h4>

        <br>
        <h1>
         <v-icon large style="color: white">mdi-phone</v-icon><a href="tel:096523429978">09652/3429978</a>
        </h1>
      </v-col>
    </v-row>
  </v-parallax>

  <v-container>
      <br><br>
      
    <div class="display-2 font-weight-bold" align="center" justify="center"  id="portfolio">Ich vertrete Sie in Ihren Interessen<br><br>sachlich, menschlich, konsequent!</div>
     <br><br><br>   
       <h2 align="center" justify="center" > Auch während der Corona-Krise stehe ich für persönliche Termine zur Verfügung. </h2>
      <br><br>
  </v-container>
    <br>
  
    <carousel class="mb-15"/>


      <v-container>

    <div class="display-1 font-weight-bold" align="center" justify="center"  id="portfolio">Juristische Kompetenz und menschliches Verständnis</div>
     <br>
          <h2 align="center" justify="center" >
          Ich bin vor Ort für Sie da.
          </h2>
          <br><br>
          <p align="justify">
            Jeder Konflikt, jeder Vertrag, jeder Fall ist anders. Deshalb ist das persönliche Gespräch zwischen Ihnen und mir, zwischen Mandanten und Anwalt, die Basis für eine individuelle Interessenvertretung und maßgeschneiderte Vertragsgestaltung.
            Dafür nehme ich mir Zeit – ich höre Ihnen zu, analysiere den Sachverhalt und berate Sie ehrlich.
            Vor Ort, regional, direkt hier in Waidhaus.
            </p>
      <br><br>
      
  </v-container>


    <v-img :src="require('@/assets/bild4.jpeg')" style="width:100%">
    <v-layout fill-height align-center>
        <v-container>
          <v-row align="center" justify="center" class="white--text">
            <v-col cols="12">
              <div
                :class="{'display-2 font-weight-bold ': $vuetify.breakpoint.smAndUp, 'title font-weight-bold': $vuetify.breakpoint.smAndDown}"
              >"Nicht Trinken und dann Autofahren.  <br> Aber falls doch: Ruf mich an!"</div>
              <div
                :class="{'headline': $vuetify.breakpoint.smAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown}"
              >- Jimmy McGill alias Saul Goodman</div>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
  </v-img>

  </div>
</template>

<script>
import Carousel from '@/components/Carousel'
export default {
  name: 'Home',
  computed:{
      rechtsgebiete: function(){
          return this.$router.options.routes.find(r => r.name == 'Rechtsgebiete').meta.dropdown
      }
  },
  components:{ Carousel }
}
</script>

<style>
#inspire img {
  width: 100%,
}

</style>

