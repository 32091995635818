<template>
  <div>
    <v-carousel class="mt-3" cycle>
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      />
    </v-carousel>
  </div>
</template>

<script>
export default {
  data: () => ({
    model: 0,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
    items: [
      {
        src: require('@/assets/bild1.jpeg')
      },
      {
        src: require('@/assets/bild2.jpeg')
      },
      {
        src: require('@/assets/bild3.jpeg')
      },
      {
        src: require('@/assets/bild5.jpeg')
      },
      {
        src: require('@/assets/bild6.jpeg')
      },
      {
        src: require('@/assets/bild7.jpeg')
      },
    ]
  })
}
</script>

